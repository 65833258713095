/**
 * Mask de Telefone
 */
var SPMaskBehavior = function (val) {
  return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
},
spOptions = {
  onKeyPress: function(val, e, field, options) {
    field.mask(SPMaskBehavior.apply({}, arguments), options);
  }
};
if ($('input[name=phone]').length > 0)
  $('input[name=phone]').mask(SPMaskBehavior, spOptions);

if ($('input[name=telefone]').length > 0)
  $('input[name=telefone]').mask(SPMaskBehavior, spOptions);

if ($('input[name=celular]').length > 0)
  $('input[name=celular]').mask(SPMaskBehavior, spOptions);

if ($('input[name=cpf]').length > 0)
  $('input[name=cpf]').mask('000.000.000-00');


  $('input[name=cep]').mask('00000-000');
  $('input[name=rg]').mask('00.000.000-0');
  $('input[name=identidade]').mask('00.000.000-0');
  $('input[name=schedule]').mask('00:00 às 00:00');
  $('input[name=date_event]').mask('00/00/0000');
  $('input[name=nascimento]').mask('00/00/0000');

