
!(function () {
  this.Pactu = function () {
    //define your this.variables here!

    this.init();
  }

  //Plugins to be initiated
  Pactu.prototype.init = function () {
    this.initToggleMenu();
    this.initToggleAsideMenu();
    this.initToggleSearch();
    this.initSwipebox();
    this.initMasks();
    this.initCarousel();
    this.initAddForms();
    this.initChangeForm();
    // this.initParallax();
  }

  /**********
  * Plugins *
  **********/

  // Change form
  Pactu.prototype.initChangeForm = function () {
    $('#selectSyndicate').on('change', function() {
      let city_id = $(this).val()

      $('.forms-list > :not(.city-id-'+city_id+')').hide()
      $('.forms-list > .city-id-'+city_id).show()
    });
  }

  // Add form
  Pactu.prototype.initAddForms = function () {
    (function() {
      var count = 2;
      $('#add').click(function() {
        var source = $('.clone:first'),
            clone = source.clone();
        clone.find(':input').attr('name', function(i, val) {
            return $(this).find(':input').attr('name')
        });
        clone.insertBefore(this);
        count++;
      });
    })()
  }

  // Parallax
  Pactu.prototype.initParallax = function () {
    // makes the parallax elements
    function parallaxIt() {

      // create variables
      var $fwindow = $(window);
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // on window scroll event
      $fwindow.on('scroll resize', function() {
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      });

      // for each of content parallax element
      $('[data-type="content"]').each(function (index, e) {
        var $contentObj = $(this);
        var fgOffset = parseInt($contentObj.offset().top);
        var yPos;
        var speed = ($contentObj.data('speed') || 1 );

        $fwindow.on('scroll resize', function (){
          yPos = fgOffset - scrollTop / speed;

          $contentObj.css('top', yPos);
        });
      });

      // for each of background parallax element
      $('[data-type="background"]').each(function(){
        var $backgroundObj = $(this);
        var bgOffset = parseInt($backgroundObj.offset().top);
        var yPos;
        var coords;
        var speed = ($backgroundObj.data('speed') || 0 );

        $fwindow.on('scroll resize', function() {
          yPos = - ((scrollTop - bgOffset) / speed);
          coords = '50% '+ yPos + 'px';

          $backgroundObj.css({ backgroundPosition: coords });
        });
      });

      // triggers winodw scroll for refresh
      $fwindow.trigger('scroll');
    };

    parallaxIt();
  }


  // open mobile menu
  Pactu.prototype.initToggleMenu = function () {
    var button = document.getElementById('js-hamburger');
    var header = document.getElementById('js-mainHeader');

    button.addEventListener('click', function(event) {
      header.classList.toggle('is-active');
    });
  }

  // open aside nav menu
  Pactu.prototype.initToggleAsideMenu = function () {
    var navButton = document.querySelectorAll('.js-navBarTrigger');

    for (i = 0; i < navButton.length; i++) {
      console.log('olar');
      navButton[i].addEventListener('click', function(event) {
        this.parentNode.classList.toggle('is-open');
      });
    }
  }



  // open search bar - mobile
  Pactu.prototype.initToggleSearch = function () {
    var button = document.getElementById('js-headerSearchIcon');
    var searchBar = document.getElementById('js-headerSearch');

    button.addEventListener('click', function(event) {
      searchBar.classList.toggle('is-active');
    });
  }


  //Zoom IMG - Swipebox
  Pactu.prototype.initSwipebox = function () {
    ;( function( $ ) {
      $( '.swipebox' ).swipebox();
    } )( jQuery );
  }

  //carousel INDEX
  Pactu.prototype.initCarousel = function () {
    $(document).ready(function(){
      $('.slick').slick({
        slidesToShow: 4,
        autoplay: true,
        dots: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              autoplay: true,
              slidesToShow: 2,
              arrows: false,
              dots: true
            }
          }
        ]
      });
    });
  }


  //Mask de Telefone
  Pactu.prototype.initMasks = function () {
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };
    if ($('input[name=phone]').length > 0)
      $('input[name=phone]').mask(SPMaskBehavior, spOptions);

    if ($('input[name=cpf]').length > 0)
      $('input[name=cpf]').mask('000.000.000-00');

    $('.cnpj-mask').mask('00.000.000/0000-00', {reverse: true});

    $('.cep-mask').mask('00000-000');

    $('.horarios-mask').mask('00:00 às 00:00');

    $('.date-mask').mask('00/00/0000');

  }

}());


//BIRL!
var Pactu = new Pactu();
